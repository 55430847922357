body, html {
  // This implements sticky footer, by making the main content extend as needed.
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  main {
    flex: 1;
  }

  .icon {
    text-decoration: none;
    &.cubing-icon {
      vertical-align: baseline;
    }
  }

  .ui.button {
    & .hidden.content {
      // Override from old Sprockets Bootstrap which hides everything marked as .hidden
      // no matter where it appears in the DOM :/
      display: block !important;
    }
  }

  .ui.dropdown > .clear.icon:before {
    content: "\f00d";
  }

  // The FUI's basic style doesn't have the '!important' tag, and hovering
  // an icon doesn't look as good as it should.
  // I suspect this is fixed in more recent version of the stylesheets, as it
  // is fixed in the online documentation.
  i.link.icon:hover,
  i.link.icons:hover {
    opacity: 1 !important;
  }

  // FIXME: yet another bootstrap conflict override :(
  .ui.modal {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    overflow: visible;
    outline: none;
  }

  .ui.form input[type=color] {
    // SCSS: "complex selectors may not be extended"
    // @extend .ui.form input[type=text];

    min-height: 38px;
  }

  .navbar-brand {
    .icon {
      margin: 0;
      width: auto;
    }
  }

  footer.ui.segment.vertical {
    border-top: 1px solid #ddd;
    // Our main content spreads a bit too close from the footer.
    margin-top: 10px;
    background-color: #f8f8f8;
    a {
      color: black;
    }
  }

  .fi {
    &.fi-tw {
      background-color: white;
      background-image: url("../images/ChineseTaipei.svg");
    }

    &.fis {
      &.fi-tw {
        background-color: white;
        background-image: url("../images/ChineseTaipeiSquared.svg");
      }
    }
  }

  i.flag {
    &.tw::before {
      background-size: contain;
      background-color: white;
      background-image: url("../images/ChineseTaipeiSquared.svg");
    }
  }

  .react-datepicker-wrapper {
    width: inherit;

    & input {
      width: inherit !important;
    }
  }

  // This is to reduce the extra margin provided for radio by bootstrap. This was making the
  // contacts page ugly, hence doing this fix.
  .ui.radio.checkbox {
    margin: 1.5px 0px;
  }

  // This overrides the Bootstrap table row color for the `active` property
  // for the SemUI `active` property.
  .ui.table > tbody > tr {
    > td.active,
    > th.active,
    &.active > td,
    &.active > th {
      background-color: #e0e0e0 !important;
    }
  }
}
